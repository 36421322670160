<template>
  <KTPortlet title="Danh sách tiệm" headSize="md">
    <template v-slot:toolbar>
      <router-link v-if="canCreateOutlet" :to="{ name: 'outlet-create' }">
        <i class="flaticon-add mr-2"></i>
        <span>Tạo mới</span>
      </router-link>
    </template>

    <template v-slot:body>
      <SearchInput
        pHolder="Tìm kiếm tên hoặc địa chỉ..."
        @search="onSearch($event)"
      ></SearchInput>

      <div class="mt-4 table-outlet table-custom">
        <b-table
          responsive
          striped
          hover
          :items="outlets"
          :fields="fields"
          :sticky-header="true"
          head-variant="light"
          v-if="outlets.length"
        >
          <template v-slot:cell(name)="data">
            <div
              class="btn-sm"
              v-b-toggle="data.value"
              @click="data.toggleDetails"
            >
              <span class="icon mr-2 icon-arrow"
                ><i
                  :class="
                    data.detailsShowing ? 'flaticon2-back' : 'flaticon2-down'
                  "
                >
                </i>
              </span>
              <span class="data-value">{{ data.value }}</span>
            </div>
          </template>
          <template v-slot:cell(status)="data">
            <b-dropdown
              right
              variant="success"
              no-caret
              id="dropdown-right"
              text="Kích hoạt"
              v-if="data.value === 1"
              size="sm"
              class="btn-status"
            >
              <b-dropdown-item
                href="#"
                v-if="canChangeOutetStatus"
                @click="changeActived(data, status.Actived)"
                class="align-items-center"
                >Kích hoạt</b-dropdown-item
              >
              <b-dropdown-item
                href="#"
                v-if="canChangeOutetStatus"
                @click="changeActived(data, status.NotActived)"
                class="align-items-center"
                >Chưa kích hoạt</b-dropdown-item
              >
            </b-dropdown>
            <b-dropdown
              right
              variant="danger"
              no-caret
              id="dropdown-right"
              text="Chưa KH"
              size="sm"
              v-else-if="data.value === 2"
              class="btn-status"
            >
              <b-dropdown-item
                href="#"
                @click="changeActived(data, status.Actived)"
                >Kích hoạt</b-dropdown-item
              >
              <b-dropdown-item
                href="#"
                @click="changeActived(data, status.NotActived)"
                >Chưa kích hoạt</b-dropdown-item
              >
            </b-dropdown>
          </template>
          <template v-slot:cell($index)="data">
            <div class="text-right edit-outlet">
              <b-dropdown
                right
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
                class="b-none"
                v-if="canChangeOutetStatus"
              >
                <template v-slot:button-content>
                  <i class="flaticon-more"></i>
                </template>
                <b-dropdown-item href="#">
                  <router-link
                    :to="{
                      name: 'outlet-edit',
                      params: { item: data.item },
                    }"
                  >
                    <div class="d-flex align-items-center">
                      <i class="flaticon-edit-1 mr-2"></i>
                      <span>Chỉnh sửa</span>
                    </div>
                  </router-link>
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </template>
          <template v-slot:row-details="row">
            <b-card class="card-content">
              <p class="card-text">
                Địa chỉ: <span class="content">{{ row.item.address }}</span>
              </p>
              <p class="card-text">
                Telephone: <span class="content">{{ row.item.telePhone }}</span>
              </p>
              <p class="card-text">
                Mobile phone:
                <span class="content">{{ row.item.mobilePhone }}</span>
              </p>
              <p class="card-text">
                Email: <span class="content">{{ row.item.email }}</span>
              </p>
              <p class="card-text">
                Manager:
                <span class="content">{{
                  getManager(row.item.managerId)
                }}</span>
              </p>
            </b-card>
          </template>
        </b-table>
        <div v-else class="d-flex justify-content-center">
          <section v-if="loading" class="loading align-items-center">
            <div class="circle"></div>
            <div class="circle"></div>
            <div class="circle"></div>
          </section>
          <div class="loading align-items-center" v-else>
            <div class="text-center">
              <h1><i class="flaticon2-open-box"></i></h1>
              <h5>Không tìm thấy dữ liệu thích hợp</h5>
            </div>
          </div>
        </div>
      </div>
      <div
        class="col-12 d-flex justify-content-center"
        v-if="totalItem > itemPage"
      >
        <b-pagination
          v-model="currentPage"
          :total-rows="totalItem"
          :per-page="itemPage"
          id="promo-table"
        ></b-pagination>
      </div>
    </template>
  </KTPortlet>
</template>

<style lang="scss">
@import "./Outlet.scss";
.loading {
  height: calc(100vh - 505px);
}
</style>

<script>
import Vue from "vue";
import { mapGetters } from "vuex";
import KTPortlet from "@/components/Portlet.vue";
import SearchInput from "@/components/SearchInput.vue";
import { Status, UserRole } from "./../../constans";
import Swal from "sweetalert2";
import Loader from "@/common/loader";
import { getAllUsersManager, getOutlets } from "@/api/outlet";

export default {
  name: "List",
  components: {
    KTPortlet,
    SearchInput,
  },

  data() {
    return {
      searchText: "",
      status: Status,
      listManager: [],
      outlets: [],
      currentPage: 1,
      totalItem: 0,
      itemPage: 10,
      loading: false,
      currentUserRole: 0,
    };
  },

  watch: {
    currentPage(val) {
      this.getListOutlet();
    },
  },

  created() {
    this.getListOutlet();
    this.$store.dispatch("titleBreadcrumb", "Quản lý tiệm");
    this.getListUser();
  },

  methods: {
    onSearch($event) {
      this.getListOutlet();
    },

    getListOutlet() {
      this.outlets = [];
      this.loading = true;

      return getOutlets({
        page: this.currentPage,
        text: this.searchText,
      })
        .then(({ data }) => {
          this.outlets = data.outlets;
          this.totalItem = data.total;
        })
        .finally(() => (this.loading = false));
    },

    changeActived(data, actived) {
      const idItem = data.item._id;
      const query = {
        id: idItem,
        bodyParams: {
          status: actived,
        },
      };
      Loader.fire();
      this.$store.dispatch("updateOutlet", query).finally(Loader.close);
    },

    getManager(id) {
      const index = this.listManager.findIndex((item) => item._id === id);
      if (index !== -1) {
        return this.listManager[index].name;
      } else {
        return "";
      }
    },

    getListUser() {
      getAllUsersManager().then((res) => {
        this.currentUserRole = JSON.parse(
          window.localStorage.getItem("user")
        ).role;
        this.listManager = res.data.filter(
          (user) => user.status === Status.Actived
        );
      });
    },
  },

  computed: {
    canCreateOutlet() {
        return this.currentUserRole > 4
    },
    canChangeOutetStatus() {
        return this.currentUserRole > 3
    },
    fields() {
      return [
        {
          key: "name",
          label: "Tên",
        },
        {
          key: "status",
          label: "Trạng thái",
        },
        {
          key: "$index",
          label: "",
        },
      ];
    },
    
  },
};
</script>
